import React, { useContext, useState } from "react";
import Login from "./Login";
import Modal from "./Modal";
import { dataContext } from "../context/DataContext";
import moment from "moment";
import { checkoutData } from "../store/checkout";
import { useAppDispatch } from "../store";
import { AuthContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";


const Seats = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
    const data = useContext(dataContext);
    const userContext = useContext(AuthContext);
  const [selectedSeat, setSelectedSeat] = useState(0);
  const submitData = () =>{
    const bookingData = {
      comment: " ",
      booked_seats: selectedSeat,
      day_passId: props?.universal_resource_defination?.id,
      date: `${moment(props?.selectedDate).format("YYYY-MM-DD")}`,
    };
    // setDayPassData(bookingData);
    dispatch(
      checkoutData({
        bookingData,
        type: "day pass",
        uuid: props?.uuid,
        credit_required:props?.resource_price && props?.resource_price * selectedSeat,
        per_cost:  props?.resource_price,
        // date: `${moment(props?.selectedDate).format("YYYY-MM-DD")}`,
        // id: props?.selectedDayPass,
        name: props?.universal_resource_defination?.public_title,
        address: props?.address,
        seats: selectedSeat,
      })
    );
    if (!!JSON.parse(window.localStorage.getItem('collabzilla-userToken') as string) && !!JSON.parse(window.localStorage.getItem('collabzilla-me') as string)) {
      navigate(`/checkout/${props?.uuid}`)
    } else {
      data?.setOpenLoginModal(true);
    }
  }
  return (
    <>
      <div className="py-2 space-y-2">
        <h2 className="text-xl font-semibold mb-2">Choose Seat</h2>

        <div className="flex flex-wrap gap-3 items-start justify-start time">
          {new Array(7).fill("")?.map((_, i) => (
            <span
              key={i}
              className={`${
                selectedSeat === i + 1
                  ? "bg-[#005395] text-white"
                  : "text-black"
              } text-sm font-normal px-3 py-1 rounded-full cursor-pointer`}
              onClick={() => setSelectedSeat(i + 1)}
            >
              {i + 1}
            </span>
          ))}
        </div>
        <hr className="mt-4 mb-2" />

        <h2 className="text-xl font-semibold mb-2">Total Estimated Price</h2>
        <h3 className="main-text text-2xl font-bold">
        {data?.settings?.currency?.symbol} {selectedSeat * props?.resource_price}
        </h3>

        <button
          data-modal-target={
            !!JSON.parse(
              window.localStorage.getItem("collabzilla-userToken") as string
            ) &&
            !!JSON.parse(window.localStorage.getItem("collabzilla-me") as any)
              ? "popup-modal"
              : "loginModal"
          }
          data-modal-toggle={
            !!JSON.parse(
              window.localStorage.getItem("collabzilla-userToken") as string
            ) &&
            !!JSON.parse(window.localStorage.getItem("collabzilla-me") as any)
              ? "popup-modal"
              : "loginModal"
          }
          className={`px-3 py-2 mt-4 w-full text-center block rounded font-semibold text-lg ${
            selectedSeat === 0 ? "link-disabled" : "main-bg"
          }`}
          onClick={submitData}
          disabled={selectedSeat === 0 ? true : false}
        >
          Book Now
        </button>
      </div>
      <Login open={data?.openLoginModal} />
      <Modal open={data?.openModal as boolean} />
    </>
  );
};

export default Seats;
