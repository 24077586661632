import { useState, useContext, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { dataContext } from "../context/DataContext";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { getAreaByCity } from "../store/areas";
import { sortImages } from "../utils/sortImages";
import noImage from "../images/no-image.png";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Page1 = () => {
  const data = useContext(dataContext);

  const [showLocation, setShowLocation] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [locationId, setLocationId] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);


  const handleCityChange = async (city: string) => {

    setSelectedCity(city);
    setShowLocation(true);
    // if (city !== selectedCity) {
    //   await data?.handleCity(city);
    // }
  };
  const setLocationChange = async (location: string) => {
    setShowInfo(true);
    setSelectedLocation(location);
    // setLocationId(locationid);
    // if (locationid!== locationId) {
    //   await data?.handleInfo(locationid);
    // }
  };

  useEffect(() => {
    setSelectedCity(data?.cities?.[0] as string);
  }, [data?.cities]);

  useEffect(() => {
    if (selectedCity !== null && selectedCity !== undefined) {

      handleCityChange(selectedCity as string);
      data?.handleCity(selectedCity as string);
    }
  }, [selectedCity]);

  useEffect(() => {
    setSelectedLocation(data?.location?.[0]?.slug as string);
    // setLocationId(data?.location?.[0]?.id);
  }, [data?.location, selectedCity]);

  useEffect(() => {
    if (selectedLocation) {
      setLocationChange(selectedLocation);
      data?.handleInfo({
        city: selectedCity,
        location: selectedLocation,
      });
    }
  }, [selectedCity, selectedLocation]);

  return (
    <>
      <section id="workspace" className="py-24">
        <div className="container mx-auto">
          <h2 className="text-3xl font-semibold text-dark">
            Working Space by City
          </h2>
          <hr className="mt-3 opacity-100" />
          <div className="workspace-area mt-3 bg-white rounded">
            {/* <!-- City Tabs Start --> */}
            <div className="city-tabs">
              <div className="text-sm font-medium text-center text-gray-500">
                {data?.isLoading ? (
                  <Spinner />
                ) : (data?.cities?.length as number) > 0 ? (
                  <ul className="flex flex-wrap -mb-px">
                    {data?.cities?.map((city, i) => (
                      <li
                        className="mr-2"
                        key={i}
                        onClick={() => handleCityChange(city)}
                      >
                        <button
                          className={`city mt-2 inline-block px-4 py-2 text-xl font-medium text-gray-500 border rounded capitalize ${
                            city === selectedCity && "city-active"
                          }`}
                        >
                          {city}
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="capitalize pt-2">No citites available</p>
                )}
              </div>
            </div>
            {/* <!-- City Tabs End --> */}
          </div>

          {/* <!-- Location Tabs Start --> */}
          {showLocation && (
            <div className="shadow-main mt-4 p-4 rounded">
              <h2 className="text-xl font-semibold mb-3">Locations</h2>
              <ul className="flex flex-wrap -mb-px">
                {data?.location?.map((location: any, i: any) => (
                  <li
                    className="mr-2"
                    key={i}
                    onClick={() => setLocationChange(location?.slug)}
                  >
                    <button
                      className={`loc-btn inline-block px-3 py-2 text-md font-medium text-black border rounded mt-2 capitalize ${
                        location?.slug === selectedLocation && "primary-bg"
                      }`}
                    >
                      {location?.area}
                    </button>
                  </li>
                ))}
              </ul>
              {/* <!-- Location Detail Start --> */}
              {showInfo && (
                <div className="mt-4 border-t-2 border-gray-400 py-3">
                  <h2 className="text-xl font-semibold mb-3">
                    Location Information
                  </h2>
                  <div className="grid grid-cols-1 gap-6">
                    {data?.info?.map((item, i) => (
                      <div
                        className="list-grid grid grid-cols-1 md:grid-cols-3 p-4 rounded shadow-main overflow-hidden gap-2 h-fit"
                        key={i}
                      >
                        <div className="left-list col-span-2">
                          <h4 className="text-2xl font-bold primary-text capitalize">
                            {item?.name}
                          </h4>
                          <h6 className="primary-text text-sm italic font-semibold my-1 capitalize">
                            {item?.address}
                          </h6>
                          <p className="text-black break-all truncate">
                            {item?.details}
                          </p>
                          <ul className="my-4">
                            <hr className=" mb-3 opacity-100" />
                            <h4 className="mb-2 primary-text text-lg font-semibold">
                              Starting From:
                            </h4>
                            {item?.Private_Office_credit &&
                            !!`${item?.Private_Office_credit}` ? (
                              <li className="mb-1">
                                <h3 className="text-lg font-semibold">
                                  Private Office :{" "}
                                  <span className="main-text">
                                    {data?.settings?.currency?.symbol}{" "}
                                    {item?.Private_Office_credit} / item
                                  </span>
                                </h3>
                              </li>
                            ) : null}
                            {item?.Meeting_Room_credit &&
                            !!`${item?.Meeting_Room_credit}` ? (
                              <li className="mb-1">
                                <h3 className="text-lg font-semibold">
                                  Meeting Room :{" "}
                                  <span className="main-text">
                                    {data?.settings?.currency?.symbol}{" "}
                                    {item?.Meeting_Room_credit} / item
                                  </span>
                                </h3>
                              </li>
                            ) : null}
                            {item?.Day_Pass_credit &&
                            !!`${item?.Day_Pass_credit}` ? (
                              <li className="mb-1">
                                <h3 className="text-lg font-semibold">
                                  Day Pass :{" "}
                                  <span className="main-text">
                                    {data?.settings?.currency?.symbol}{" "}
                                    {item?.Day_Pass_credit} / item
                                  </span>
                                </h3>
                              </li>
                            ) : null}
                          </ul>
                          <hr className="mb-2" />
                          <ul>
                            <h4 className="mb-2 primary-text text-lg font-semibold">
                              Location Features:
                            </h4>
                            {item?.facilities?.map((facility: any) => (
                              <li className="flex mb-2" key={facility?.assets?.id}>
                                <img
                                  src={`${facility?.assets?.image_url}`}
                                  className="w-6 h-6"
                                  alt=""
                                />
                                <p className="ml-1">Free {facility?.name}</p>
                              </li>
                            ))}
                          </ul>
                          <Link
                            to={`booking/${item?.uuid}`}
                            className="main-bg mt-2 px-3 py-2 w-fit text-center block rounded font-semibold text-lg"
                          >
                            Book Now
                          </Link>
                        </div>
                        <div className="right-list">
                          <div className="place-slider">
                            {/* <h4 className="text-lg mb-3 font-semibold">Property Images</h4> */}
                            {item?.location_assets?.length > 0 ? (
                              <Slider {...sliderSettings}>
                                {sortImages(item?.location_assets)?.map(
                                  (image: any) => (
                                    <img
                                      key={image?.id}
                                      src={`${image?.image_url}`}
                                      alt={image?.id}
                                      className="object-cover"
                                    />
                                  )
                                )}
                              </Slider>
                            ) : (
                              <img src={noImage} alt="no image" />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* <!-- Location Detail End --> */}
            </div>
          )}
          {/* <!-- Location Tabs End --> */}
        </div>
      </section>
    </>
  );
};

export default Page1;
