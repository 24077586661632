import React, { useContext, useEffect, useState } from "react";
import DatePickerComponent from "../datepicker";
import { dataContext } from "../../context/DataContext";
import Seats from "../Seats";
import Slots from "../Slots";
import Lead from "../Lead";

const Index = ({ locationById, selectedTab }: any) => {
  const data = useContext(dataContext);
  const [showDate, setShowDate] = useState<Boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedPrivateOfficeId, setSelectedPrivateOfficeId] = useState<number | null>(
    null
  );
  const [showOffice, setShowOffice] = useState<Boolean>(false);
  const [cardInfo, setCardInfo] = useState<any>({});

  useEffect(() => {
    setSelectedPrivateOfficeId(null);
  }, [selectedTab]);

  useEffect(() => {
    setShowDate(false);
  }, [selectedTab]);

  return (
    <>
      <div className="shadow-main mt-4 p-4 rounded">
        <h2 className="text-xl font-semibold mb-2">Available Rooms</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {locationById?.data?.length === 0 ? (
            <>
              <p className="capitalize pt-2">No Rooms Available Right Now</p>
            </>
          ) : (
            (locationById?.data as any[])?.map((info: any, i) => (
              <div
                className="room-selection px-3 py-4 text-md font-medium text-black border rounded relative"
                key={i}
              >
                <div className="room-feat">
                  <h6 className="font-semibold main-text text-2xl capitalize">
                    {info?.universal_resource_defination?.public_title}
                  </h6>
                  <p className="mt-1 break-all capitalize">
                    {info?.description}
                  </p>
                  <h3 className="text-lg font-semibold my-2">
                    Per Slot Price :{" "}
                    <span className="main-text ">{data?.settings?.currency?.symbol} {info?.resource_price}</span>
                  </h3>
                  <hr className="mb-2" />
                  <ul>
                    <h4 className="mb-2 primary-text text-lg font-semibold">
                      Room Features:
                    </h4>
                    {info?.universal_resource_defination?.facilities?.length >
                    0 ? (
                      info?.universal_resource_defination?.facilities?.map(
                        (facility: any, i: any) => (
                          <li className="flex mb-2" key={i}>
                            <img
                              src={`${facility?.assets?.[0]?.image_url}`}
                              alt={`${facility?.name}`}
                              className="w-6 h-6"
                            />
                            <p className="ml-1">Free {facility?.name}</p>
                          </li>
                        )
                      )
                    ) : (
                      <p className="capitalize PY-2">no features are listed.</p>
                    )}
                  </ul>
                  <div className="radio-wrapper-31 float-right absolute bottom-2 right-2">
                    {/* <input
                          type="radio"
                          className="w-6 h-6 text-yellow-400 bg-gray-100 border-gray-300 focus:ring-yellow-500"
                          id="redd"
                          onChange={() => setShowDate(true)}
                        /> */}

                    <input
                      id="yellow-radio"
                      type="radio"
                      value={info?.id}
                      name="colored-radio"
                      className="w-6 h-6 text-[#005395] bg-gray-100 border-gray-300 focus:ring-[#005395] focus:ring-2"
                      onChange={(e) => {
                        setShowDate(true);
                        setSelectedPrivateOfficeId(Number(e.target.value));
                        setCardInfo(info);
                        setShowOffice(false);
                        setSelectedDate(new Date());
                      }}
                      checked={selectedPrivateOfficeId === info?.id}
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        {showDate && (
          <>
            <hr className="mt-4 mb-2" />

            <h2 className="text-xl font-semibold mb-2">Choose Date</h2>
            <DatePickerComponent
              setSelectedDate={setSelectedDate}
              onChange={(e: any) => {
                data?.setSlotError(null);
                // setSelectedDate(e.target.value);
                setShowOffice(true);
               cardInfo?.booking_type === 'SLOT_BOOKING' && data?.handleMeetingSlots({
                  date: selectedDate,
                  id: selectedPrivateOfficeId,
                });
              }}
            />
            {showOffice && cardInfo?.booking_type === 'SLOT_BOOKING' && (
                <Slots
                selectedDate={selectedDate}
                selectedMeetingRoom={selectedPrivateOfficeId}
                {...cardInfo}
                />
            )}

            {showOffice && cardInfo?.booking_type === 'DAY_BOOKING' && (
              <Seats
                selectedDate={selectedDate}
                selectedDayPass={selectedPrivateOfficeId}
                {...cardInfo}
              />
            )}
            {showOffice && cardInfo?.booking_type === 'LEAD_GENRATION' && (
              // <Seats
              //   selectedDate={selectedDate}
              //   selectedDayPass={selectedPrivateOfficeId}
              //   {...cardInfo}
              // />
              <Lead selectedDate={selectedDate} {...cardInfo}/>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Index;
